import "./Dashboard.css";
import {useNavigate} from "react-router-dom";
import React, {useState} from "react";
import HomePage from "../Pages/HomePage";
import UserView from "../Pages/UserView";
import Settings from "../Pages/Settings";
import {HomeOutlined, LogoutOutlined, SettingOutlined, UserOutlined,} from "@ant-design/icons";

import { Typography, Divider} from "antd";

const {Title} = Typography;


function Dashboard() {
    const [activeSection, setActiveSection] = useState("home");

    const server_url = "https://newsbotdev.newrlabs.com";
    const accessToken = localStorage.getItem("accessToken");
    const navigate = useNavigate();


    // -------------------------------------
    // Sidebar Navigation
    // -------------------------------------
    const handleLogout = () => {
        localStorage.removeItem("accessToken");
        navigate("/");
    };

    // -------------------------------------
    // Render Pages
    // -------------------------------------
    const renderHome = () => <HomePage/>;

    const renderUserView = () => <UserView/>;

    const renderSettings = () => <Settings server_url={server_url}/>;

    // -------------------------------------
    // Conditional Rendering by activeSection
    // -------------------------------------
    const renderContent = () => {
        switch (activeSection) {
            case "home":
                return renderHome();
            case "userView":
                return renderUserView();

            case "settings":
                return renderSettings();
            default:
                return renderHome();
        }
    };

    return (
        <div className="dashboard-container">
            <aside className="sidebar">
                <Title level={3} style={{textAlign: "center", color: "#FFFFFF", marginBottom: "10px"}}>
                    Swadesi Admin
                </Title>
                <button
                    onClick={() => setActiveSection("home")}
                    className={activeSection === "home" ? "active" : ""}
                >
                    <HomeOutlined style={{marginRight: 8}}/>
                    Home
                </button>
                <button
                    onClick={() => setActiveSection("userView")}
                    className={activeSection === "userView" ? "active" : ""}
                >
                    <UserOutlined style={{marginRight: 8}}/>
                    View Users
                </button>

                <button
                    onClick={() => setActiveSection("settings")}
                    className={activeSection === "settings" ? "active" : ""}
                >
                    <SettingOutlined style={{marginRight: 8}}/>
                    Settings
                </button>
                <button onClick={handleLogout}>
                    <LogoutOutlined style={{marginRight: 8}}/>
                    Logout
                </button>
            </aside>
            <main className="main-content">{renderContent()}</main>
        </div>
    );
}

export default Dashboard;
