import React, {useState} from "react";
import {useNavigate} from "react-router-dom";
import {Button, Form, Input, message, Typography} from "antd";
import {EyeInvisibleOutlined, EyeOutlined} from "@ant-design/icons";
import "./Login.css";
import bot from "../assets/admin-login-2.png";

const {Title, Paragraph} = Typography;

const Login = () => {
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();
    const server_url = "https://newsbotdev.newrlabs.com";

    const handleSubmit = async (values) => {
        setIsLoading(true);
        try {
            const response = await fetch(`${server_url}/login/admin`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded",
                },
                body: new URLSearchParams({
                    username: values.email,
                    password: values.password,
                }),
            });

            if (!response.ok) {
                setIsLoading(false);
                throw new Error("Incorrect username or password");
            }

            const data = await response.json();
            localStorage.setItem("accessToken", data.access_token);
            setIsLoading(false);
            message.success("Login Successful!");
            navigate("/Dashboard");
        } catch (error) {
            setIsLoading(false);
            message.error("Login Failed: " + error.message);
        }
    };

    return (
        <div className="login-container">
            {/* Left Side: Form */}
            <div className="login-left">
                <div className="login-form-wrapper">
                    <Title className="login-title">Swadesi Admin</Title>
                    <Title level={3} className="login-title">
                        Sign In to Admin Panel
                    </Title>
                    <Paragraph className="login-subtitle">
                        Please enter your details to continue
                    </Paragraph>

                    <Form layout="vertical" onFinish={handleSubmit} requiredMark={false}>
                        <Form.Item
                            label="Email or username"
                            name="email"
                            rules={[
                                {required: true, message: "Please enter your email"},
                                {type: "email", message: "Please enter a valid email"},
                            ]}
                        >
                            <Input placeholder="Email or username"/>
                        </Form.Item>

                        <Form.Item
                            label="Password"
                            name="password"
                            rules={[
                                {required: true, message: "Please enter your password"},
                            ]}
                        >
                            <Input.Password
                                placeholder="Enter your password"
                                iconRender={(visible) =>
                                    visible ? <EyeOutlined/> : <EyeInvisibleOutlined/>
                                }
                            />
                        </Form.Item>

                        {/* Example of a "Forgot password?" link */}
                        {/*<div className="login-forgot">*/}
                        {/*  <a href="#" onClick={(e) => e.preventDefault()}>*/}
                        {/*    Forgot password?*/}
                        {/*  </a>*/}
                        {/*</div>*/}

                        <Form.Item>
                            <Button
                                type="primary"
                                htmlType="submit"
                                block
                                loading={isLoading}
                                className="login-button"
                            >
                                {isLoading ? "Authenticating..." : "Sign in"}
                            </Button>
                        </Form.Item>
                    </Form>

                    {/*<div className="login-new-account">*/}
                    {/*  <span>New to Swadesi Admin?</span>*/}
                    {/*  <a href="#" onClick={(e) => e.preventDefault()}>*/}
                    {/*    {" "}*/}
                    {/*    Sign up!*/}
                    {/*  </a>*/}
                    {/*</div>*/}
                </div>
            </div>

            {/* Right Side: Gradient Background */}
            <div className="login-right">
                <img src={bot} alt="logo" className="app-logo-login"/>
            </div>
        </div>
    );
};

export default Login;
