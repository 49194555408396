import React, {useEffect, useState} from "react";
import {Button, Card, message, Popconfirm, Spin, Table, Typography} from "antd";

const {Title, Paragraph} = Typography;

const UserView = () => {
    const [users, setUsers] = useState([]);
    const [loading, setLoading] = useState(false);
    const server_url = "https://newsbotdev.newrlabs.com";
    const accessToken = localStorage.getItem("accessToken");

    useEffect(() => {
        fetchUsers();
    }, []);

    const fetchUsers = async () => {
        setLoading(true);
        try {
            const response = await fetch(`${server_url}/admin/get-users/`, {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            });
            if (response.ok) {
                const data = await response.json();
                setUsers(data);
            } else {
                message.error("Failed to fetch users");
            }
        } catch (error) {
            console.error("Error fetching users:", error);
            message.error("An error occurred while fetching users");
        } finally {
            setLoading(false);
        }
    };

    const deleteUser = async (email) => {
        try {
            const response = await fetch(`${server_url}/admin/delete-user/`, {
                method: "DELETE",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${accessToken}`,
                },
                body: JSON.stringify({email}),
            });
            const data = await response.json();
            if (response.ok) {
                message.success(data.detail);
                setUsers(users.filter((user) => user.email !== email));
            } else {
                message.error(data.detail || "Failed to delete user");
            }
        } catch (error) {
            console.error("Error deleting user:", error);
            message.error("An error occurred while deleting the user");
        }
    };

    const columns = [
        {
            title: "Name",
            dataIndex: "name",
            key: "name",
        },
        {
            title: "Email",
            dataIndex: "email",
            key: "email",
        },
        {
            title: "Role",
            dataIndex: "role",
            key: "role",
        },
        {
            title: "Authentication Method",
            dataIndex: "auth_method",
            key: "auth_method",
        },

        {
            title: "Created At",
            dataIndex: "created_at",
            key: "created_at",
            render: (text) => new Date(text).toLocaleString(),
        },
        {
            title: "Actions",
            key: "actions",
            render: (_, record) => (
                <Popconfirm
                    title="Are you sure you want to delete this user?"
                    onConfirm={() => deleteUser(record.email)}
                    okText="Yes"
                    cancelText="No"
                >
                    <Button type="primary" danger>
                        Delete
                    </Button>
                </Popconfirm>
            ),
        },
    ];

    return (
        <div style={{padding: "40px", maxWidth: "1400px", margin: "0 auto"}}>
            <div style={{textAlign: "center", marginBottom: "30px"}}>
                <Title level={2} style={{marginBottom: "5px", color: "#333"}}>
                    App Users
                </Title>
                <Paragraph style={{color: "#555"}}>
                    View and manage registered users and their subscriptions. Use this dashboard to quickly monitor and
                    remove user accounts as
                    needed.
                </Paragraph>
            </div>
            <Card bordered={false} style={{backgroundColor: "#fff", borderRadius: "8px", padding: "20px"}}>
                {loading ? (
                    <div style={{textAlign: "center", marginTop: "50px"}}>
                        <Spin size="large"/>
                    </div>
                ) : (
                    <Table
                        dataSource={users}
                        columns={columns}
                        rowKey="email"
                        pagination={{pageSize: 10}}
                        bordered
                        style={{borderRadius: "8px"}}
                    />
                )}
            </Card>
        </div>
    );
};

export default UserView;
